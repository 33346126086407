import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#9BCC40',
				secondary: '#9BCC40',
				guest_primary: '#557EB6',
				rejected_application: '#F64747',
				acepted_application: '#34DC84',
				progress_application: '#0066A4',
				new_application: '#F3901D',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#43A047',
				fail: '#D32F2F',
				warning: '#FFC107'
			}
		}
	},
	icons: {
		iconfont: 'mdi'
	}
});
