<template>
  <v-app>
    <core-view />
    <!-- Start application's alerts -->
    <v-snackbar v-model="snackbar" :color="color">
      <v-layout row wrap>
        <v-flex sm8>
      <p v-html="text" class="app_alert_text" style="margin-top:4%;" />
        </v-flex>
        <v-flex sm4>
      <v-btn :color="btn_text_color" text @click="snackbar = false" v-html="alert_close_text" />
        </v-flex>
      </v-layout>
    </v-snackbar>
    <!-- End application's alerts -->
  </v-app>
</template>

<script>
/**
 *
 * General documentation:
 * alerts: this.$store.commit('toggle_alert', {color: '', text: ''})
 *
 */
export default {
  data: () => ({
    snackbar: false,
    btn_text_color: "white",
    alert_close_text: "Cerrar",
  }),
  watch: {
    // set snackbar alert to toggled
    alert() {
      this.snackbar = this.alert;
    },
    // set VueX state:alert to the current value
    snackbar() {
      this.$store.commit("set_alert", this.snackbar);
    },
    login_status: function () {
      if (this.login_status) {
        if (this.user_type == 0) {
          this.$router.push("/admin/Home");
        } else {
          this.$router.push("/operator/Home");
        }
      } else {
        this.$router.push("/");
      }
    },
  },
  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreFooter: () => import("@/components/core/Footer"),
    CoreFab: () => import("@/components/core/Fab"),
    CoreView: () => import("@/components/core/View"),
  },
  computed: {
    login_status() {
      return this.$store.state.login_in_status;
    },
    user_type() {
      return this.$store.state.user_type;
    },
    token() {
      return this.$store.state.token;
    },
    alert() {
      return this.$store.state.alert;
    },
    text() {
      return this.$store.state.text;
    },
    color() {
      return this.$store.state.color;
    },
    user_type_exists() {
      return (
        localStorage.getItem("user-type") !== null &&
        (localStorage.getItem("user-type") == 0 ||
          localStorage.getItem("user-type") == 1 ||
          localStorage.getItem("user-type") == 2)
      );
    },
  },
  beforeMount() {
    if (this.token !== "" && this.token !== null) {
      this.$store.commit("toggle_auth", true);
    }
    // if user type does not exist and the user is logged in -> log it out
    if (!this.user_type_exists && this.login_status) {
      this.logout();
    }
    // if token does not exist and the user is logged in -> log it out
    if (this.token == null && this.login_status) {
      this.logout();
    }
    var countDownDate = new Date(localStorage.getItem("date-start")).getTime();
    var now = new Date().getTime();
    var distance = countDownDate - now;
    if (localStorage.getItem("timer-status") == "true" && distance <= 0) {
      localStorage.setItem("timer-status", "false") == "true";
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then((resp) => {
          this.$store.commit("toggle_alert", resp);
          this.$router.go();
        })
        .catch((err) => {
          this.$store.commit("toggle_alert", err);
        });
    },
  },
};
</script>

<style lang="sass">
section
  padding: 64px 16px

  @media screen and (max-width: 640px)
    padding: 16px
</style>
<style>
.theme--light.v-data-table thead tr:last-child th {
  border-bottom: 2px solid rgba(0, 0, 0, 1);
}
.theme--light.v-data-table thead tr th {
  color: #000000 !important;
  opacity: 1 !important;
  font-family: "Raleway", sans-serif !important;
  font-weight: 600 !important;
}
.dialog_title_text {
  font-weight: bold;
  font-family: "Open sans", sans-serif;
  font-size: 25px;
  color: rgba(0, 0, 0, 0.852);
}
.view_title_text {
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.852);
}
p {
  font-family: "Oswald", sans-serif !important;
}
/* Start overwrite PLACEHOLDER color for login view */
#login_view .theme--light.v-input input,
.theme--light.v-input textarea,
.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: rgba(33, 29, 29, 0.605);
  margin-left: 10px;
  margin-bottom: 2px;
  font-size: 13px;
}
/* End overwrite PLACEHOLDER color for login view */
/* Start overwrite PLACEHOLDER color for form views */
#form_view .theme--light.v-input input,
.theme--light.v-input textarea,
.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: rgba(33, 29, 29, 0.4);
  margin-left: 10px;
  margin-bottom: 2px;
  font-size: 13px;
}
/* Start overwrite PLACEHOLDER color for form views */
.app_alert_text {
  margin: auto;
  font-family: "Open sans", sans-serif;
}
.v-btn {
  text-transform: none;
}
</style>
