import { set, toggle } from '@/utils/vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import router from './router';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		drawer: null,
		login_in_status: false,
		text: null,
		color: null,
		alert: false,
		token: localStorage.getItem('user-token') || '',
		user_type: localStorage.getItem('user-type') || null,
		user_name: localStorage.getItem('user-name') || null,
		timer_begun: localStorage.getItem('timer-status') || false,
		date_start: localStorage.getItem('date-start') || null,
		bEditAuto: false
	},
	mutations: {
		toggleEditAutomatic(state, value) {
			state.bEditAuto = value;
		},
		toggle_alert(state, alert) {
			state.text = alert.text;
			state.color = alert.color;
			state.alert = true;
		},
		toggle_auth(state, value) {
			state.login_in_status = value;
		},
		toggle_type(state, value) {
			state.user_type = value;
		},
		set_alert(state, value) {
			state.alert = value;
		},
		auth_error(err) {
			localStorage.removeItem('user-token');
			localStorage.removeItem('user-type');
		},
		auth_success(state, response) {
			console.log("aqui")
			console.log(response)
			localStorage.setItem('user-token', response.token);
			localStorage.setItem('user-name',response.user.name)
			localStorage.setItem('user-type', 0);
			state.token = response.token;
		}
	},
	actions: {
		login({ commit }, user) {
			const uri = 'https://api.ecohouse.mx'; // 'http://ec2-52-91-114-127.compute-1.amazonaws.com'
			return new Promise((resolve, reject) => {
				var body = new URLSearchParams();
				body.append('email', user.sEmail);
				body.append('password', user.sPassword);
				Axios.post(`${uri}/api/login`, body)
					.then((resp) => {
						commit('auth_success', resp.data);
						commit('toggle_auth', true);
						if (resp.data.iRole === 0) {
							router.push('/admin/Home');
							router.go();
						} else {
							router.push('/operator/Home');
							router.go();
						}
						resolve(resp);
					})
					.catch((err) => {
						localStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
						commit('auth_error', err);
						reject(err);
					});
			});
		},
		logout({ commit }) {
			return new Promise((resolve, reject) => {
				try {
					localStorage.removeItem('user-token'); // if the request fails, remove any possible user token if possible
					localStorage.removeItem('user-type'); // if the request fails, remove any possible user token if possible
					localStorage.removeItem('user-session');
					localStorage.removeItem('OutageEdit');
					localStorage.removeItem('timer-status');
					localStorage.removeItem('date-start');
					commit('toggle_auth', false);
					resolve({ color: 'success', text: 'Sesión terminada' });
				} catch (e) {
					reject({ color: 'fail', text: 'Hubo un error terminando la sesíon, favor de intentar de nuevo.' });
				}
			});
		}
	}
});
