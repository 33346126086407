import Vue from 'vue';
import './plugins/components';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import apexcharts from 'vue-apexcharts';
import load from 'lodash';
import encode from './json_encoder.js';
import error_handler from './error_handler.js';


Vue.config.productionTip = false;

window.uri = 'https://api.ecohouse.mx/'; // 'http://ec2-52-91-114-127.compute-1.amazonaws.com'
window.db = axios;
window._ = load;
window.encode = encode;
window.error_handler = error_handler;
window.apexchart = apexcharts;

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
